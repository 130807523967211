import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot } from '@angular/router';
import { BasePage } from '@pages/base/base.page';

@Injectable({
	providedIn: 'root'
})
export class LoginGuard extends BasePage implements CanActivate {
	async canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean> {
		this.storageService.get('user').then((data: any) => {
			if (!data) {
				this.navCtrl.navigateRoot('/start');
				return false;
			}
		});

		this.navCtrl.navigateRoot('/tabs/tickets');
		return true;
	}
}
