import { Injector, NgModule } from '@angular/core';
import { BrowserModule, HammerModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';

import { IonicModule, IonicRouteStrategy } from '@ionic/angular';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { ServiceWorkerModule } from '@angular/service-worker';
import { environment } from '../environments/environment';
import { HttpClient, HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { ScreenTrackingService, UserTrackingService } from '@angular/fire/analytics';
import { AngularFireModule } from '@angular/fire/compat';
import { AngularFireMessagingModule } from '@angular/fire/compat/messaging';
import { IonicStorageModule } from '@ionic/storage-angular';
import { StorageService } from './services/storage.service';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { UserService } from './services/user.service';
import { QRCodeModule } from 'angularx-qrcode';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { AuthInterceptor } from './services/interceptor.service';

import { LOCALE_ID } from '@angular/core';
import { registerLocaleData } from '@angular/common';
import localePt from '@angular/common/locales/pt';
import { WebrtcService } from './services/services';
import { CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { IonCurrencyMaskModule } from './modules/ion-currency-mask';
import { NgCalendarModule } from 'ionic2-calendar';

registerLocaleData(localePt);

export class ServiceLocator {
	static injector: Injector;
}

export function createTranslateLoader(http: HttpClient) {
	return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}

@NgModule({
	declarations: [AppComponent],
	imports: [
		BrowserModule,
		FormsModule,
		ReactiveFormsModule,
		IonicModule.forRoot({
			mode: 'md',
			backButtonText: ''
		}),
		IonicStorageModule.forRoot(),
		HttpClientModule,
		AppRoutingModule,
		ServiceWorkerModule.register('combined-sw.js', {
			enabled: environment.production,
			// Register the ServiceWorker as soon as the app is stable
			// or after 30 seconds (whichever comes first).
			registrationStrategy: 'registerWhenStable:30000'
		}),
		AngularFireModule.initializeApp(environment.firebase),
		AngularFireMessagingModule,
		QRCodeModule,
		TranslateModule.forRoot({
			defaultLanguage: 'pt',
			loader: {
				provide: TranslateLoader,
				useFactory: createTranslateLoader,
				deps: [HttpClient]
			}
		}),
		IonCurrencyMaskModule,
		NgCalendarModule,
		HammerModule
	],
	providers: [
		{ provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
		{ provide: LOCALE_ID, useValue: 'pt-PT' },
		ScreenTrackingService,
		UserTrackingService,
		StorageService,
		UserService,
		{ provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
		WebrtcService
	],
	bootstrap: [AppComponent],
	schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class AppModule {
	constructor(private injector: Injector) {
		ServiceLocator.injector = this.injector;
	}
}
