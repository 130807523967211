import { Injectable } from '@angular/core';

@Injectable({
	providedIn: 'root'
})
export class NavigationService {
	redirectUrl: string = '';
	constructor() {}

	getRedirect() {
		return this.redirectUrl;
	}

	setRedirect(url) {
		this.redirectUrl = url;
	}
}
