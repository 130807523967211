import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { BraceletGuard } from './guards/bracelet.guard';
import { AcceptGuard } from './guards/accept.guard';
import { LoginGuard } from './guards/login.guard';

const routes: Routes = [
	{
		path: '',
		loadChildren: () => import('./tabs/tabs.module').then((m) => m.TabsPageModule),
		canActivate: [AcceptGuard]
	},
	{
		path: 'login',
		loadChildren: () => import('@pages/login/login.module').then((m) => m.LoginPageModule)
	},
	{
		path: 'recover',
		loadChildren: () => import('@pages/recover/recover.module').then((m) => m.RecoverPageModule)
	},
	{
		path: 'register',
		loadChildren: () => import('@pages/register/register.module').then((m) => m.RegisterPageModule)
	},
	{
		path: 'home',
		loadChildren: () => import('@pages/home/home.module').then((m) => m.HomePageModule)
	},
	{
		path: 'tickets',
		loadChildren: () => import('@pages/tickets/tickets.module').then((m) => m.TicketsPageModule)
	},
	{
		path: 'ticket',
		loadChildren: () => import('@pages/ticket/ticket.module').then((m) => m.TicketPageModule)
	},
	{
		path: 'wallet',
		loadChildren: () => import('@pages/wallet/wallet.module').then((m) => m.WalletPageModule)
	},
	{
		path: 'services',
		loadChildren: () => import('@pages/services/services.module').then((m) => m.ServicesPageModule)
	},
	{
		path: 'account',
		loadChildren: () => import('@pages/account/account.module').then((m) => m.AccountPageModule)
	},
	{
		path: 'base',
		loadChildren: () => import('@pages/base/base.module').then((m) => m.BasePageModule)
	},
	{
		path: 'about',
		loadChildren: () => import('@app/pages/about/about.module').then((m) => m.AboutPageModule)
	},
	{
		path: 'partner-card',
		loadChildren: () => import('@pages/partner-card/partner-card.module').then((m) => m.PartnerCardPageModule)
	},
	{
		path: 'html',
		loadChildren: () => import('@pages/html/html.module').then((m) => m.HtmlPageModule)
	},
	{
		path: 'aauav',
		loadChildren: () => import('@app/pages/aauav/aauav.module').then((m) => m.AauavPageModule)
	},
	{
		path: 'password',
		loadChildren: () => import('@pages/password/password.module').then((m) => m.PasswordPageModule)
	},
	{
		path: 'faqs',
		loadChildren: () => import('@pages/faqs/faqs.module').then((m) => m.FaqsPageModule)
	},
	{
		path: 'faq',
		loadChildren: () => import('@pages/faq/faq.module').then((m) => m.FaqPageModule)
	},
	{
		path: 'edit',
		loadChildren: () => import('@pages/edit/edit.module').then((m) => m.EditPageModule)
	},
	{
		path: 'start',
		loadChildren: () => import('@pages/start/start.module').then((m) => m.StartPageModule)
	},
	{
		path: 'code',
		loadChildren: () => import('@app/pages/code/code.module').then((m) => m.CodePageModule)
	},
	{
		path: 'set-password',
		loadChildren: () => import('@pages/set-password/set-password.module').then((m) => m.SetPasswordPageModule)
	},
	{
		path: 'event',
		loadChildren: () => import('@pages/event/event.module').then((m) => m.EventPageModule)
	},
	{
		path: 'novelty',
		loadChildren: () => import('@app/pages/novelty/novelty.module').then((m) => m.NoveltyPageModule)
	},
	{
		path: 'entrance',
		loadChildren: () => import('@pages/entrance/entrance.module').then((m) => m.EntrancePageModule)
	},
	{
		path: 'entrance/:id',
		loadChildren: () => import('./pages/entrance-buy/entrance-buy.module').then((m) => m.EntranceBuyPageModule)
	},
	{
		path: 'country',
		loadChildren: () => import('./pages/country/country.module').then((m) => m.CountryPageModule)
	},
	{
		path: 'tickets-history',
		loadChildren: () => import('./pages/tickets-history/tickets-history.module').then((m) => m.TicketsHistoryPageModule)
	},
	{
		path: 'deposit',
		loadChildren: () => import('./pages/deposit/deposit.module').then((m) => m.DepositPageModule)
	},
	{
		path: 'messages',
		loadChildren: () => import('./pages/messages/messages.module').then((m) => m.MessagesPageModule)
	},
	{
		path: 'messages/:id',
		loadChildren: () => import('./pages/message/message.module').then((m) => m.MessagePageModule)
	},
	{
		path: 'scan',
		loadChildren: () => import('./pages/scan/scan.module').then((m) => m.ScanPageModule)
	},
	{
		path: 'download',
		loadChildren: () => import('./pages/download/download.module').then((m) => m.DownloadPageModule)
	},
	{
		path: 'cards',
		loadChildren: () => import('./pages/cards/cards.module').then((m) => m.CardsPageModule)
	},
	{
		path: 'card',
		loadChildren: () => import('./pages/card/card.module').then((m) => m.CardPageModule)
	},
	{
		path: 'transfer',
		loadChildren: () => import('./pages/transfer/transfer.module').then((m) => m.TransferPageModule)
	},
	{
		path: 'add-bracelet',
		loadChildren: () => import('./pages/add-bracelet/add-bracelet.module').then((m) => m.AddBraceletPageModule)
	},
	{
		path: 'scan-qr',
		loadChildren: () => import('./pages/scan-qr/scan-qr.module').then((m) => m.ScanQrPageModule)
	},
	{
		path: 'bracelet/:id',
		loadChildren: () => import('@pages/home/home.module').then((m) => m.HomePageModule),
		canActivate: [BraceletGuard]
	},
	{
		path: 'calendar/:id',
		loadChildren: () => import('@pages/calendar/calendar.module').then((m) => m.CalendarPageModule)
	},
	{
		path: 'cart',
		loadChildren: () => import('@pages/cart/cart.module').then((m) => m.CartPageModule)
	},
	{
		path: 'payment',
		loadChildren: () => import('@pages/payment/payment.module').then((m) => m.PaymentPageModule)
	},
	{
		path: 'accept',
		loadChildren: () => import('@pages/accept/accept.module').then((m) => m.AcceptPageModule)
	},
	{
		path: 'movements/:id',
		loadChildren: () => import('./pages/movements/movements.module').then((m) => m.MovementsPageModule)
	},
	{
		path: 'send-sms',
		loadChildren: () => import('./pages/send-sms/send-sms.module').then((m) => m.SendSmsPageModule)
	},
	{
		path: 'ticket/show',
		canActivate: [LoginGuard],
		loadChildren: () => import('@pages/tickets/tickets.module').then((m) => m.TicketsPageModule)
	},
	{
		path: 'ticket-transfers',
		loadChildren: () => import('./pages/ticket-transfers/ticket-transfers.module').then((m) => m.TicketTransfersPageModule)
	},
	{
		path: 'transfer-history',
		loadChildren: () => import('./pages/transfer-history/transfer-history.module').then((m) => m.TransferHistoryPageModule)
	},
	{
		path: 'calendar-list/:id',
		loadChildren: () => import('@pages/calendar-list/calendar-list.module').then((m) => m.CalendarListPageModule)
	}
];
@NgModule({
	imports: [RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })],
	exports: [RouterModule]
})
export class AppRoutingModule {}
