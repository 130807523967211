import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot } from '@angular/router';
import { BasePage } from '@pages/base/base.page';
import { BraceletService } from '@services/bracelet.service';
import { NavController } from '@ionic/angular';

@Injectable({
	providedIn: 'root'
})
export class AcceptGuard extends BasePage implements CanActivate {
	constructor(public navCtrl: NavController) {
		super();
	}

	async canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean> {
		this.storageService.get('user').then((data: any) => {
			if (data && !data.display_accepts) {
				this.navCtrl.navigateRoot('/accept');
				return false;
			}
		});

		return true;
	}
}
