import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot } from '@angular/router';
import { BasePage } from '@pages/base/base.page';
import { BraceletService } from '@services/bracelet.service';
import { NavController } from '@ionic/angular';

@Injectable({
	providedIn: 'root'
})
export class BraceletGuard extends BasePage implements CanActivate {
	constructor(public navCtrl: NavController, private braceletService: BraceletService) {
		super();
	}

	async canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean> {
		const bracelet = route.params.id;
		return await this.checkBracelet(bracelet);
	}

	private checkBracelet(bracelet: string) {
		return new Promise<boolean>((resolve, reject) => {
			this.storageService.get('user').then((user: any) => {
				if (!user) {
					this.storageService.set('request_bracelet', bracelet);
					this.navCtrl.navigateRoot('/start');
					resolve(false);
				} else {
					this.braceletService.onePwa().subscribe((data: any) => {
						if (data.status === 'OK') {
							if (data.data.bracelet) {
								this.toastService.show('danger', this.translateService.instant('Ja tem uma pulseira associada'));
							} else {
								this.braceletService.create(bracelet).subscribe((resp: any) => {
									let status = 'danger';

									if (resp.status === 'OK') {
										status = 'success';
									}

									this.toastService.show(status, resp.message);
								});
							}
						}

						resolve(true);
					});
				}
			});
		});
	}
}
