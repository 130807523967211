import { Component, NgZone } from '@angular/core';
import { Router } from '@angular/router';
import { Platform } from '@ionic/angular';
import { addIcons } from 'ionicons';
import { MessageService } from './services/message.service';
import { StorageService } from './services/storage.service';
import { ToastService } from './services/toast.service';
import { UserService } from './services/user.service';
// import { Device } from '@capacitor/device';
import { environment } from 'src/environments/environment';
import { SettingsService } from '@services/settings.service';
import { v4 as uuidv4 } from 'uuid';
import { UpdateService } from './services/update.service';
import { register } from 'swiper/element/bundle';
import { SplashScreen } from '@capacitor/splash-screen';
import { App, URLOpenListenerEvent } from '@capacitor/app';

register();

@Component({
	selector: 'app-root',
	templateUrl: 'app.component.html',
	styleUrls: ['app.component.scss']
})
export class AppComponent {
	constructor(
		public storageService: StorageService,
		public userService: UserService,
		public messageService: MessageService,
		public toastService: ToastService,
		public router: Router,
		public platform: Platform,
		private settingsService: SettingsService,
		public updateService: UpdateService,
		private zone: NgZone
	) {
		this.userService.init();
		addIcons({
			calendar: './assets/images/icons/calendar.svg',
			facebook: './assets/images/icons/facebook.svg',
			google: './assets/images/icons/google.svg',
			services: './assets/images/icons/services.svg',
			ticket: './assets/images/icons/ticket.svg',
			user: './assets/images/icons/user.svg',
			wallet: './assets/images/icons/wallet.svg',
			history: './assets/images/icons/history.svg',
			heart: './assets/images/icons/heart.svg',
			dots: './assets/images/icons/dots.svg',
			favorite: './assets/images/icons/favorite.svg'
		});

		this.messageService.listen();

		this.messageService.get().subscribe((data: any) => {
			if (data) {
				let url = 'tabs/wallet';
				switch (data.data.type) {
					case 'CART':
						url = 'tabs/tickets';
						break;
					default:
						break;
				}
				this.toastService.show('success', data.data.title);
				this.router.navigate([url]);
			}
		});

		this.storageService.get('device_identifier').then((data: any) => {
			if (!data) {
				this.storageService.set('device_identifier', uuidv4());
			}
		});

		// if (this.platform.is('desktop')) {
		// 	this.router.navigate(['download']);
		// }

		this.getSettings();

		this.updateService.checkForUpdates();

		SplashScreen.hide();

		App.addListener('appUrlOpen', (event: URLOpenListenerEvent) => {
			this.zone.run(() => {
				const slug = event.url.split('app-aauav.pt').pop();
				if (slug) {
					this.router.navigateByUrl(slug);
				}
			});
		});
	}

	getSettings() {
		this.settingsService.all().subscribe((data: any) => {
			if (data.status === 'OK') {
				this.storageService.set('settings', data.data);

				const settings = data.data;

				if (settings.version) {
					if (this.versionCompare(settings.version, environment.version, null) == 1) {
						this.updateService.checkForUpdates();
					}
				}
			}
		});
	}

	versionCompare(v1, v2, options) {
		var lexicographical = options && options.lexicographical,
			zeroExtend = options && options.zeroExtend,
			v1parts = v1.split('.'),
			v2parts = v2.split('.');

		function isValidPart(x) {
			return (lexicographical ? /^\d+[A-Za-z]*$/ : /^\d+$/).test(x);
		}

		if (!v1parts.every(isValidPart) || !v2parts.every(isValidPart)) {
			return NaN;
		}

		if (zeroExtend) {
			while (v1parts.length < v2parts.length) v1parts.push('0');
			while (v2parts.length < v1parts.length) v2parts.push('0');
		}

		if (!lexicographical) {
			v1parts = v1parts.map(Number);
			v2parts = v2parts.map(Number);
		}

		for (var i = 0; i < v1parts.length; ++i) {
			if (v2parts.length == i) {
				return 1;
			}

			if (v1parts[i] == v2parts[i]) {
				continue;
			} else if (v1parts[i] > v2parts[i]) {
				return 1;
			} else {
				return -1;
			}
		}

		if (v1parts.length != v2parts.length) {
			return -1;
		}

		return 0;
	}
}
