import { Injectable } from '@angular/core';
import { ApiService } from './api.service';

@Injectable({
	providedIn: 'root'
})
export class BraceletService {
	private controller = 'bracelet/';

	constructor(private api: ApiService) {}

	create(number: string) {
		return this.api.post(`${this.controller}create`, { number });
	}

	onePwa() {
		return this.api.get(`${this.controller}one-pwa`);
	}

	movements(id: any) {
		return this.api.get(`${this.controller}movements`, { id });
	}

	removeUser(id: number) {
		return this.api.get(`${this.controller}remove-user`, { id });
	}

	block(id: number) {
		return this.api.get(`${this.controller}block`, { id });
	}
}
