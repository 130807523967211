import { Injectable } from '@angular/core';
import CryptoJS from 'crypto-js';
import { Storage } from '@ionic/storage';

@Injectable()
export class StorageService {
	private secretKey = 'tERcEsgNiKCUf';
	private _storage: Storage | null = null;
	constructor(private storage: Storage) {
		this.init();
	}

	async init() {
		const storage = await this.storage.create();
		this._storage = storage;
	}

	async get(key: string): Promise<any> {
		return await this.storage.get('aauapwa-' + key);
	}

	public set(key: string, value: any) {
		this._storage?.set('aauapwa-' + key, value);
	}

	public remove(key: string) {
		this.storage.remove('aauapwa-' + key);
	}

	getPasswordEncrypted(password: string): string {
		// Decrypt
		const bytes = CryptoJS.AES.decrypt(password, this.secretKey);
		const plaintext = bytes.toString(CryptoJS.enc.Utf8);
		return plaintext;
	}

	setPasswordEncrypted(password: string): string {
		// Encrypt
		const ciphertext = CryptoJS.AES.encrypt(password, this.secretKey).toString();
		return ciphertext;
	}
}
