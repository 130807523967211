import { Injectable } from '@angular/core';
import { StorageService } from './storage.service';
import { ApiService } from './api.service';
import { environment } from 'src/environments/environment';
import { EventsService } from './events.service';

@Injectable({
	providedIn: 'root'
})
export class UserService {
	private controller = 'user/';
	user = null;

	constructor(public storageService: StorageService, private api: ApiService, private eventsService: EventsService) {}

	async init() {
		this.storageService.get('user').then((user: any) => {
			if (user) {
				this.api.setAuth(user.auth_key);
			}
		});
	}

	set(user: any) {
		this.eventsService.publish('user:update', user);
		this.user = user;
		this.storageService.set('user', user);
		const authkey = user ? user.auth_key : null;
		this.api.setAuth(authkey);
	}

	remove() {
		this.user = null;
		this.storageService.set('user', null);
		this.api.setAuth(null);
		this.eventsService.publish('user:logout');
	}

	view() {
		return this.api.get(this.controller + 'view');
	}

	wallet() {
		return this.api.get(this.controller + 'wallet');
	}

	addBracelet(number) {
		return this.api.post(this.controller + 'add-bracelet', { number });
	}

	edit(data: any) {
		return this.api.post(this.controller + 'edit', data);
	}

	delete() {
		return this.api.get(this.controller + 'delete', null);
	}

	setPassword(password: string) {
		return this.api.post(this.controller + 'set-password', { password });
	}

	setImage(file: string) {
		return this.api.post(this.controller + 'set-image', { file });
	}

	covidCertValidate() {
		return this.api.post(this.controller + 'covid-cert-validate', null);
	}

	covidCertDelete() {
		return this.api.get(this.controller + 'covid-cert-delete');
	}

	/*selectFile() {
		return new Promise((resolve, reject) => {
			this.chooser
				.getFile('application/pdf')
				.then((file) => {
					resolve(file.uri);
				})
				.catch((e) => reject(e));
		});
	}*/

	uploadCert(file: any, link: string): Promise<any> {
		return new Promise((resolve, reject) => {
			this.storageService.get('user').then(async (user: any) => {
				let formData = new FormData();
				formData.append('file', file, file.name);

				try {
					const response = await fetch(environment.baseUrl + link, {
						headers: {
							Authorization: user.auth_key
						},
						method: 'POST',
						body: formData
					});

					resolve(await response.json());
				} catch (err) {
					console.log(err);
					reject(err);
				}
			});
		});
	}

	/*uploadCert(file: any, link: string): Promise<any> {
		return new Promise((resolve, reject) => {
			this.storageService.get('user').then((user: any) => {
				var options: FileUploadOptions = {
					fileKey: 'file',
					fileName: file.name.substr(file.name.lastIndexOf('/') + 1),
					chunkedMode: false,
					mimeType: 'pdf',
					headers: { Authorization: user.auth_key }
				};

				const fileTransfer: FileTransferObject = FileTransfer.create();

				fileTransfer.upload(file, environment.baseUrl + link, options, true).then(
					(data) => {
						resolve(JSON.parse(data.response));
					},
					(error) => {
						reject(error);
					}
				);
			});
		});
	}*/
}
