import { Injectable } from '@angular/core';
import { ApiService } from './api.service';
import { InAppBrowser } from '@capgo/inappbrowser';
import { NavController, Platform } from '@ionic/angular';

@Injectable({
	providedIn: 'root'
})
export class PaymentService {
	private controller = 'payment/';

	constructor(private api: ApiService, public navCtrl: NavController, public platform: Platform) {
		InAppBrowser.addListener('urlChangeEvent', (event) => {
			if (
				event.url.indexOf('/payment/confirmed') !== -1 ||
				event.url.indexOf('/payment/canceled') !== -1 ||
				event.url.indexOf('/fail_auth') !== -1
			) {
				InAppBrowser.close();
				this.navCtrl.navigateRoot(['tabs/wallet']);
			}

			if (event.url.indexOf('/response/ok') !== -1) {
				InAppBrowser.close();
				this.navCtrl.navigateRoot(['tabs/wallet']);
			}

			if (event.url.indexOf('response/error') !== -1 || event.url.indexOf('response/authentication_already_used') !== -1) {
				InAppBrowser.close();
				this.navCtrl.navigateRoot(['tabs/wallet']);
			}
		});
	}

	create(data: any) {
		return this.api.post(this.controller + 'create', data);
	}

	confirm(data: any) {
		return this.api.post(this.controller + 'confirm', data);
	}

	gatewayStatus() {
		return this.api.get(this.controller + 'gateway-status');
	}

	checkout(data) {
		return this.api.post(this.controller + 'checkout', data);
	}

	openURL(url: string, title: string = '') {
		if (this.platform.is('capacitor')) {
			InAppBrowser.openWebView({ url, title });
		} else {
			window.location.assign(url);
		}
	}
}
