import { Injectable } from '@angular/core';

import jsQR from 'jsqr';

declare var document;
declare var window;
declare var imageFilter;
declare var navigator;

import * as webrtc from './common';
import * as capture from './capture';
import { BehaviorSubject } from 'rxjs';
import { Platform } from '@ionic/angular';

export interface ConfigWebrtc {
	facingMode: any;
	appContainer: string;
	localVideo: string;
	localStream: string;
}

@Injectable()
export class WebrtcService {
	defaultConfig: ConfigWebrtc = {
		appContainer: '.ion-page',
		localVideo: '.local-video',
		localStream: '.local-stream',
		facingMode: 'environment'
	};
	config: any;
	canvas: any;
	localVideo: any;
	canvasContext: any;
	globalID: number;
	isBindind: boolean;
	isRunning: boolean;

	showControls = false;

	bSubject: BehaviorSubject<any> = new BehaviorSubject(false);

	constructor(public platform: Platform) {
		window.commonRTC = webrtc;
	}

	finish(value) {
		this.stop();
		webrtc.cameraOff();
		this.bSubject.next(value);
	}

	scan() {
		return new Promise((resolve, reject) => {
			// this.events.publish('alert', 'input', (data, role) => {
			// 	if (role == 'cancel') {
			// 		this.events.publish('toast', 'showAlert', 'Aviso', 'Leitura do QR Code foi cancelada');
			// 	} else {
			// 		this.finish(data.value);
			// 		resolve(data.value);
			// 	}
			// 	console.log('data:', data, role);
			// });
		});
	}

	timeout(ms) {
		return new Promise((resolve) => setTimeout(resolve, ms));
	}

	async init(config?: any) {
		this.config = config ? config : this.defaultConfig;
		this.config.isCordova = this.platform.is('cordova');
		//console.log("webrtc configs:",this.config)
		const inited = await webrtc.setup(this.config);
		if (inited) {
			this.localVideo = document.querySelector(this.config.localVideo);

			this.canvas = document.createElement('canvas');
		} else {
			if (this.platform.is('cordova')) {
				// this.events.publish(
				// 	'toast',
				// 	'confirmAlert',
				// 	'Aviso',
				// 	'Sem permissões de acesso a Câmara. Para usar a câmera altere as permissões',
				// 	() => {
				// 		this.open('application_details');
				// 	},
				// 	null,
				// 	'Permitir'
				// );
			}
		}
		this.start();
		return inited;
	}

	open(options) {
		if (window.cordova && window.cordova.plugins.settings) {
			console.log('openNativeSettingsTest is active');
			window.cordova.plugins.settings.open(
				options,
				function () {
					console.log('opened settings');
				},
				function () {
					console.log('failed to open settings');
				}
			);
		} else {
			console.log('openNativeSettingsTest is not active!');
		}
	}

	toggle() {
		this.isRunning = !this.isRunning;
		console.log('toogle::', this.isRunning);
		this.isRunning ? this.start() : this.stop();
	}

	async start(timeout = 120000) {
		webrtc.cameraOn();
		this.localVideo = document.querySelector(this.config.localVideo);

		capture.loop(
			this.config.appContainer,
			this.tick,
			() => {
				webrtc.cameraOff();
			},
			(t) => {
				this.bSubject.next(t ? 0 : false);
				webrtc.cameraOff();
			},
			timeout
		);

		this.localVideo.play();
		// await this.loadingCtrl
		// 	.create({
		// 		duration: 100
		// 	})
		// 	.present();
		const appContainer = document.querySelector(this.config.appContainer);
		appContainer.classList.remove('capture');
		appContainer.classList.add('capture');

		return;
	}

	stop() {
		this.isRunning = false;
		capture.cancel();
	}

	resume() {
		this.isRunning ? this.start() : this.stop();
	}

	switchCamera() {
		webrtc.switchCamera();
		this.resume();
	}

	async save() {
		let canvas = document.createElement('canvas');

		canvas.height = this.localVideo.videoHeight;
		canvas.width = this.localVideo.videoWidth;
		await this.draw(canvas, this.localVideo, false);
		let saveplugin = (window as any).canvas2ImagePlugin;
		if (saveplugin) {
			saveplugin.saveImageDataToLibrary(
				(msg) => {
					console.log(msg);
					canvas = null;
				},
				(err) => {
					console.log(err);
					canvas = null;
				},
				canvas
			);
		}
	}

	public captureFrame() {
		this.localVideo = document.querySelector(this.config.localVideo);
		if (this.localVideo.readyState === this.localVideo.HAVE_ENOUGH_DATA) {
			return this.localVideo;
		}
		return null;
	}

	clear(canvas) {
		canvas.remove();
		//canvas.width = canvas.height = 0;
		//canvas = null;
	}

	public tick = async () => {
		const localVideo = this.captureFrame();
		if (localVideo && this.canvas) {
			this.isRunning = true;
			let [w, h] = [localVideo.videoWidth, localVideo.videoHeight];
			let canvas = this.canvas;

			canvas.width = w;
			canvas.height = h;

			const filter = await this.filter(canvas);
			if (filter) {
				return await this.qrCodeDecode(canvas);
			} else {
				this.clear(canvas);
				return false;
			}
		} else {
			await this.timeout(500);
		}

		return false;
	};

	async loadImg(data): Promise<any> {
		let image = new Image();
		return new Promise<any>((resolve, reject) => {
			image.onload = function () {
				resolve(image);
			};
			image.onerror = function () {
				resolve(true);
			};
			image.src = data;
		});
	}

	async filterIos(imageData: ImageData | HTMLImageElement): Promise<ImageData | null | HTMLImageElement> {
		return new Promise<ImageData | null | HTMLImageElement>((resolve, reject) => {
			window.imageFilter.applyFilter(
				{
					srcUrl: imageData,
					filter: { name: 'SepiaTone', intensity: 0.7 }
				},
				async (filteredImg) => {
					await this.loadImg(filteredImg);
					resolve(filteredImg);
				},
				() => {
					resolve(null);
				}
			);
		});
	}

	async draw(canvas, data, wait = true) {
		if (wait) await this.loadImg(data);
		const ctx = canvas.getContext('2d');
		ctx.rect(0, 0, canvas.width, canvas.height);
		await ctx.drawImage(data, 0, 0, canvas.width, canvas.height);
		await this.timeout(1);
		return true;
	}

	async filter(canvas) {
		try {
			const ctx = canvas.getContext('2d');
			if (this.platform.is('ios')) {
				await this.draw(canvas, this.localVideo, false);
				//const filter=await this.filterIos(canvas.toDataURL())
				//await this.draw(canvas,filter,false)
				//await this.filterIos(canvas.toDataURL())
			} else {
				ctx.imageSmoothingEnabled = false;
				ctx.textBaseline = 'top';
				ctx.filter = 'contrast(1.4) sepia(1)';
				await this.draw(canvas, this.localVideo, false);
			}
			return true;
		} catch (error) {
			console.log('error on filter:', error);
			return false;
		}
	}

	async ctxToQrcode(canvas) {
		const ctx = canvas.getContext('2d');
		if (canvas.width && canvas.height) {
			return ctx.getImageData(0, 0, canvas.width, canvas.height);
		}
		return null;
	}

	async qrCodeDecode(canvas) {
		const imageData = await this.ctxToQrcode(canvas);
		if (imageData) {
			const code = jsQR(imageData.data, imageData.width, imageData.height, {
				inversionAttempts: 'dontInvert'
			});
			//console.log("code:",code)
			if (code && code.data) {
				this.finish(code.data);
				this.clear(canvas);
				return code.data;
			}
		}
		this.clear(canvas);
		return false;
	}
}
