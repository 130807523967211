import { Injectable } from '@angular/core';
import { ApiService } from './api.service';

@Injectable({
	providedIn: 'root'
})
export class FacebookPageService {
	private controller = 'facebook-page/';

	constructor(private api: ApiService) {}

	all() {
		return this.api.get(this.controller + 'all');
	}

	one(id: string) {
		return this.api.get(this.controller + 'one', { id });
	}
}
