import { Injectable } from '@angular/core';
import { ApiService } from './api.service';

@Injectable({
	providedIn: 'root'
})
export class PartnerService {
	private controller: string = 'partner/';

	constructor(private api: ApiService) {}

	join() {
		return this.api.get(this.controller + 'join');
	}

	one() {
		return this.api.get(this.controller + 'one');
	}

	validate(data: any) {
		return this.api.post(this.controller + 'validate', data);
	}
}
