import { Injectable } from '@angular/core';
import { AngularFireMessaging } from '@angular/fire/compat/messaging';
import { BehaviorSubject } from 'rxjs';
import { ApiService } from './api.service';
import { isSupported } from 'firebase/messaging';
import { Capacitor } from '@capacitor/core';
import { PushNotifications, Token, PushNotificationSchema, ActionPerformed } from '@capacitor/push-notifications';
import { DeviceService } from './device.service';
import { Platform } from '@ionic/angular';

@Injectable({
	providedIn: 'root'
})
export class MessageService {
	private messages$: BehaviorSubject<object>;
	private controller: string = 'message/';

	constructor(
		private api: ApiService,
		public afMessaging: AngularFireMessaging,
		public deviceService: DeviceService,
		public platform: Platform
	) {
		this.messages$ = new BehaviorSubject(null);

		if (Capacitor.isNativePlatform()) {
			PushNotifications.addListener('registration', (token: Token) => {
				this.deviceService.create(token.value).subscribe((data) => {});
			});

			// Some issue with our setup and push will not work
			PushNotifications.addListener('registrationError', (error: any) => {
				console.log('Error on registration: ' + JSON.stringify(error));
			});

			// Show us the notification payload if the app is open on our device
			PushNotifications.addListener('pushNotificationReceived', (notification: PushNotificationSchema) => {
				console.log('pushNotificationReceived', notification);
				this.messages$.next(notification);
			});

			// Method called when tapping on a notification
			PushNotifications.addListener('pushNotificationActionPerformed', (notification: ActionPerformed) => {
				console.log('pushNotificationActionPerformed', notification);
				this.messages$.next(notification.notification);
			});
		}
	}

	all(page: any) {
		return this.api.get(this.controller + 'all', { page });
	}

	latest() {
		return this.api.get(this.controller + 'latest');
	}

	one(id: string) {
		return this.api.get(this.controller + 'one', { id });
	}

	open(id: string) {
		return this.api.get(this.controller + 'open', { id });
	}

	count() {
		return this.api.get(this.controller + 'count');
	}

	delete(id: string) {
		return this.api.post(this.controller + 'delete', { id });
	}

	requestPermission() {
		return new Promise<any>((resolve, reject) => {
			if (!this.platform.is('capacitor')) {
				isSupported().then((data: any) => {
					if (data) {
						this.afMessaging.requestToken.subscribe(
							(token) => {
								this.deviceService.create(token).subscribe(
									() => {
										this.listen();
										resolve(true);
									},
									() => {
										reject(false);
									}
								);
							},
							(error) => {
								reject(error);
							}
						);
					} else {
						return reject('unsupported');
					}
				});
			} else {
				PushNotifications.requestPermissions().then((result) => {
					if (result.receive === 'granted') {
						// Register with Apple / Google to receive push via APNS/FCM
						PushNotifications.register();
						resolve(true);
					} else {
						reject(false);
						console.log(result.receive);
						// Show some error
					}
				});
			}
		});
	}

	listen() {
		return new Promise<any>((resolve, reject) => {
			this.afMessaging.messages.subscribe(
				(message) => {
					this.messages$.next(message);
					resolve(message);
				},
				(error) => {
					reject(error);
				}
			);
		});
	}

	listenMessages() {
		isSupported().then((data: any) => {
			if (!data) {
				var maxTime = 60;
				var time = 0;
				var messageID = null;
				var func = () => {
					time++;
					this.latest().subscribe((data: any) => {
						if (!messageID || data.data.id === messageID) {
							messageID = data.data.id;
						} else {
							this.messages$.next({ data: data.data });
							clearInterval(interval);
						}
					});
					if (time > maxTime) {
						clearInterval(interval);
					}
				};
				var interval = setInterval(func, 5000);
			}
		});
	}

	get() {
		return this.messages$;
	}

	bulkRead(id: string[]) {
		return this.api.post(this.controller + 'bulkread', { id });
	}
	bulkDelete(id: string[]) {
		return this.api.post(this.controller + 'bulkdelete', { id });
	}
}
