import { Injectable } from '@angular/core';

@Injectable({
	providedIn: 'root'
})
export class CountryService {
	languages: any[] = [
		{ code: 'pt', name: 'Português' },
		{ code: 'en', name: 'English' },
		{ code: 'es', name: 'Espanol' }
	];

	countries: any[] = [
		{ code: 'af', name: 'Afghanistan', phone_code: '93' },
		{ code: 'al', name: 'Albania', phone_code: '355' },
		{ code: 'dz', name: 'Algeria', phone_code: '213' },
		{ code: 'as', name: 'American Samoa', phone_code: '1684' },
		{ code: 'ad', name: 'Andorra', phone_code: '376' },
		{ code: 'ao', name: 'Angola', phone_code: '244' },
		{ code: 'ai', name: 'Anguilla', phone_code: '1264' },
		{ code: 'ag', name: 'Antigua and Barbuda', phone_code: '1268' },
		{ code: 'ar', name: 'Argentina', phone_code: '54' },
		{ code: 'am', name: 'Armenia', phone_code: '374' },
		{ code: 'aw', name: 'Aruba', phone_code: '297' },
		{ code: 'au', name: 'Australia', phone_code: '61' },
		{ code: 'at', name: 'Austria', phone_code: '43' },
		{ code: 'az', name: 'Azerbaijan', phone_code: '994' },
		{ code: 'bs', name: 'Bahamas', phone_code: '1242' },
		{ code: 'bh', name: 'Bahrain', phone_code: '973' },
		{ code: 'bd', name: 'Bangladesh', phone_code: '880' },
		{ code: 'bb', name: 'Barbados', phone_code: '1246' },
		{ code: 'by', name: 'Belarus', phone_code: '375' },
		{ code: 'be', name: 'Belgium', phone_code: '32' },
		{ code: 'bz', name: 'Belize', phone_code: '501' },
		{ code: 'bj', name: 'Benin', phone_code: '229' },
		{ code: 'bm', name: 'Bermuda', phone_code: '1441' },
		{ code: 'bt', name: 'Bhutan', phone_code: '975' },
		{ code: 'bo', name: 'Bolivia', phone_code: '591' },
		{ code: 'ba', name: 'Bosnia and Herzegovina', phone_code: '387' },
		{ code: 'bw', name: 'Botswana', phone_code: '267' },
		{ code: 'br', name: 'Brazil', phone_code: '55' },
		{ code: 'io', name: 'British Indian Ocean Territory', phone_code: '246' },
		{ code: 'bn', name: 'Brunei Darussalam', phone_code: '673' },
		{ code: 'bg', name: 'Bulgaria', phone_code: '359' },
		{ code: 'bf', name: 'Burkina Faso', phone_code: '226' },
		{ code: 'bi', name: 'Burundi', phone_code: '257' },
		{ code: 'kh', name: 'Cambodia', phone_code: '855' },
		{ code: 'cm', name: 'Cameroon', phone_code: '237' },
		{ code: 'ca', name: 'Canada', phone_code: '1' },
		{ code: 'cv', name: 'Cape Verde', phone_code: '238' },
		{ code: 'ky', name: 'Cayman Islands', phone_code: '345' },
		{ code: 'cf', name: 'Central African Republic', phone_code: '236' },
		{ code: 'td', name: 'Chad', phone_code: '235' },
		{ code: 'cl', name: 'Chile', phone_code: '56' },
		{ code: 'cn', name: 'China', phone_code: '86' },
		{ code: 'cx', name: 'Christmas Island', phone_code: '61' },
		{ code: 'cc', name: 'Cocos (Keeling) Islands', phone_code: '61' },
		{ code: 'co', name: 'Colombia', phone_code: '57' },
		{ code: 'km', name: 'Comoros', phone_code: '269' },
		{ code: 'cg', name: 'Congo', phone_code: '242' },
		{ code: 'cd', name: 'Congo, The Democratic Republic of the', phone_code: '243' },
		{ code: 'ck', name: 'Cook Islands', phone_code: '682' },
		{ code: 'cr', name: 'Costa Rica', phone_code: '506' },
		{ code: 'ci', name: 'Cote dIvoire', phone_code: '225' },
		{ code: 'hr', name: 'Croatia', phone_code: '385' },
		{ code: 'cu', name: 'Cuba', phone_code: '53' },
		{ code: 'cy', name: 'Cyprus', phone_code: '537' },
		{ code: 'cz', name: 'Czech Republic', phone_code: '420' },
		{ code: 'dk', name: 'Denmark', phone_code: '45' },
		{ code: 'dj', name: 'Djibouti', phone_code: '253' },
		{ code: 'dm', name: 'Dominica', phone_code: '1767' },
		{ code: 'do', name: 'Dominican Republic', phone_code: '1849' },
		{ code: 'ec', name: 'Ecuador', phone_code: '593' },
		{ code: 'eg', name: 'Egypt', phone_code: '20' },
		{ code: 'sv', name: 'El Salvador', phone_code: '503' },
		{ code: 'gq', name: 'Equatorial Guinea', phone_code: '240' },
		{ code: 'er', name: 'Eritrea', phone_code: '291' },
		{ code: 'ee', name: 'Estonia', phone_code: '372' },
		{ code: 'et', name: 'Ethiopia', phone_code: '251' },
		{ code: 'fk', name: 'Falkland Islands (Malvinas)', phone_code: '500' },
		{ code: 'fo', name: 'Faroe Islands', phone_code: '298' },
		{ code: 'fj', name: 'Fiji', phone_code: '679' },
		{ code: 'fi', name: 'Finland', phone_code: '358' },
		{ code: 'fr', name: 'France', phone_code: '33' },
		{ code: 'gf', name: 'French Guiana', phone_code: '594' },
		{ code: 'pf', name: 'French Polynesia', phone_code: '689' },
		{ code: 'ga', name: 'Gabon', phone_code: '241' },
		{ code: 'gm', name: 'Gambia', phone_code: '220' },
		{ code: 'ge', name: 'Georgia', phone_code: '995' },
		{ code: 'de', name: 'Germany', phone_code: '49' },
		{ code: 'gh', name: 'Ghana', phone_code: '233' },
		{ code: 'gi', name: 'Gibraltar', phone_code: '350' },
		{ code: 'gr', name: 'Greece', phone_code: '30' },
		{ code: 'gl', name: 'Greenland', phone_code: '299' },
		{ code: 'gd', name: 'Grenada', phone_code: '1473' },
		{ code: 'gp', name: 'Guadeloupe', phone_code: '590' },
		{ code: 'gu', name: 'Guam', phone_code: '1671' },
		{ code: 'gt', name: 'Guatemala', phone_code: '502' },
		{ code: 'gg', name: 'Guernsey', phone_code: '44' },
		{ code: 'gn', name: 'Guinea', phone_code: '224' },
		{ code: 'gw', name: 'Guinea-Bissau', phone_code: '245' },
		{ code: 'gy', name: 'Guyana', phone_code: '595' },
		{ code: 'ht', name: 'Haiti', phone_code: '509' },
		{ code: 'hn', name: 'Honduras', phone_code: '504' },
		{ code: 'hk', name: 'Hong Kong', phone_code: '852' },
		{ code: 'hu', name: 'Hungary', phone_code: '36' },
		{ code: 'is', name: 'Iceland', phone_code: '354' },
		{ code: 'in', name: 'India', phone_code: '91' },
		{ code: 'id', name: 'Indonesia', phone_code: '62' },
		{ code: 'ir', name: 'Iran', phone_code: '98' },
		{ code: 'iq', name: 'Iraq', phone_code: '964' },
		{ code: 'ie', name: 'Ireland', phone_code: '353' },
		{ code: 'im', name: 'Isle of Man', phone_code: '44' },
		{ code: 'il', name: 'Israel', phone_code: '972' },
		{ code: 'it', name: 'Italy', phone_code: '39' },
		{ code: 'jm', name: 'Jamaica', phone_code: '1876' },
		{ code: 'jp', name: 'Japan', phone_code: '81' },
		{ code: 'je', name: 'Jersey', phone_code: '44' },
		{ code: 'jo', name: 'Jordan', phone_code: '962' },
		{ code: 'kz', name: 'Kazakhstan', phone_code: '7' },
		{ code: 'ke', name: 'Kenya', phone_code: '254' },
		{ code: 'ki', name: 'Kiribati', phone_code: '686' },
		{ code: 'kp', name: 'Korea, Democratic Peoples Republic of', phone_code: '850' },
		{ code: 'kr', name: 'Korea, Republic of', phone_code: '82' },
		{ code: 'kw', name: 'Kuwait', phone_code: '965' },
		{ code: 'kg', name: 'Kyrgyzstan', phone_code: '996' },
		{ code: 'la', name: 'Lao Peoples Democratic Republic', phone_code: '856' },
		{ code: 'lv', name: 'Latvia', phone_code: '371' },
		{ code: 'lb', name: 'Lebanon', phone_code: '961' },
		{ code: 'ls', name: 'Lesotho', phone_code: '266' },
		{ code: 'lr', name: 'Liberia', phone_code: '231' },
		{ code: 'ly', name: 'Libyan', phone_code: '218' },
		{ code: 'li', name: 'Liechtenstein', phone_code: '423' },
		{ code: 'lt', name: 'Lithuania', phone_code: '370' },
		{ code: 'lu', name: 'Luxembourg', phone_code: '352' },
		{ code: 'mo', name: 'Macao', phone_code: '853' },
		{ code: 'mk', name: 'Macedonia', phone_code: '389' },
		{ code: 'mg', name: 'Madagascar', phone_code: '261' },
		{ code: 'mw', name: 'Malawi', phone_code: '265' },
		{ code: 'my', name: 'Malaysia', phone_code: '60' },
		{ code: 'mv', name: 'Maldives', phone_code: '960' },
		{ code: 'ml', name: 'Mali', phone_code: '223' },
		{ code: 'mt', name: 'Malta', phone_code: '356' },
		{ code: 'mh', name: 'Marshall Islands', phone_code: '692' },
		{ code: 'mq', name: 'Martinique', phone_code: '596' },
		{ code: 'mr', name: 'Mauritania', phone_code: '222' },
		{ code: 'mu', name: 'Mauritius', phone_code: '230' },
		{ code: 'yt', name: 'Mayotte', phone_code: '262' },
		{ code: 'mx', name: 'Mexico', phone_code: '52' },
		{ code: 'fm', name: 'Micronesia', phone_code: '691' },
		{ code: 'md', name: 'Moldova', phone_code: '373' },
		{ code: 'mc', name: 'Monaco', phone_code: '377' },
		{ code: 'mn', name: 'Mongolia', phone_code: '976' },
		{ code: 'me', name: 'Montenegro', phone_code: '382' },
		{ code: 'ms', name: 'Montserrat', phone_code: '1664' },
		{ code: 'ma', name: 'Morocco', phone_code: '212' },
		{ code: 'mz', name: 'Mozambique', phone_code: '258' },
		{ code: 'mm', name: 'Myanmar', phone_code: '95' },
		{ code: 'na', name: 'Namibia', phone_code: '264' },
		{ code: 'nr', name: 'Nauru', phone_code: '674' },
		{ code: 'np', name: 'Nepal', phone_code: '977' },
		{ code: 'nl', name: 'Netherlands', phone_code: '31' },
		{ code: 'an', name: 'Netherlands Antilles', phone_code: '599' },
		{ code: 'nc', name: 'New Caledonia', phone_code: '687' },
		{ code: 'nz', name: 'New Zealand', phone_code: '64' },
		{ code: 'ni', name: 'Nicaragua', phone_code: '505' },
		{ code: 'ne', name: 'Niger', phone_code: '227' },
		{ code: 'ng', name: 'Nigeria', phone_code: '234' },
		{ code: 'nu', name: 'Niue', phone_code: '683' },
		{ code: 'nf', name: 'Norfolk Island', phone_code: '672' },
		{ code: 'mp', name: 'Northern Mariana Islands', phone_code: '1670' },
		{ code: 'no', name: 'Norway', phone_code: '47' },
		{ code: 'om', name: 'Oman', phone_code: '968' },
		{ code: 'pk', name: 'Pakistan', phone_code: '92' },
		{ code: 'pw', name: 'Palau', phone_code: '680' },
		{ code: 'ps', name: 'Palestinian', phone_code: '970' },
		{ code: 'pa', name: 'Panama', phone_code: '507' },
		{ code: 'pg', name: 'Papua New Guinea', phone_code: '675' },
		{ code: 'py', name: 'Paraguay', phone_code: '595' },
		{ code: 'pe', name: 'Peru', phone_code: '51' },
		{ code: 'ph', name: 'Philippines', phone_code: '63' },
		{ code: 'pn', name: 'Pitcairn', phone_code: '872' },
		{ code: 'pl', name: 'Poland', phone_code: '48' },
		{ code: 'pt', name: 'Portugal', phone_code: '351' },
		{ code: 'pr', name: 'Puerto Rico', phone_code: '1939' },
		{ code: 'qa', name: 'Qatar', phone_code: '974' },
		{ code: 're', name: 'Réunion', phone_code: '262' },
		{ code: 'ro', name: 'Romania', phone_code: '40' },
		{ code: 'ru', name: 'Russia', phone_code: '7' },
		{ code: 'rw', name: 'Rwanda', phone_code: '250' },
		{ code: 'bl', name: 'Saint Barthélemy', phone_code: '590' },
		{ code: 'sh', name: 'Saint Helena', phone_code: '290' },
		{ code: 'kn', name: 'Saint Kitts and Nevis', phone_code: '1869' },
		{ code: 'lc', name: 'Saint Lucia', phone_code: '1758' },
		{ code: 'mf', name: 'Saint Martin', phone_code: '590' },
		{ code: 'pm', name: 'Saint Pierre and Miquelon', phone_code: '508' },
		{ code: 'vc', name: 'Saint Vincent and the Grenadines', phone_code: '1784' },
		{ code: 'ws', name: 'Samoa', phone_code: '685' },
		{ code: 'sm', name: 'San Marino', phone_code: '378' },
		{ code: 'st', name: 'Sao Tome and Principe', phone_code: '239' },
		{ code: 'sa', name: 'Saudi Arabia', phone_code: '966' },
		{ code: 'sn', name: 'Senegal', phone_code: '221' },
		{ code: 'rs', name: 'Serbia', phone_code: '381' },
		{ code: 'sc', name: 'Seychelles', phone_code: '248' },
		{ code: 'sl', name: 'Sierra Leone', phone_code: '232' },
		{ code: 'sg', name: 'Singapore', phone_code: '65' },
		{ code: 'sk', name: 'Slovakia', phone_code: '421' },
		{ code: 'si', name: 'Slovenia', phone_code: '386' },
		{ code: 'sb', name: 'Solomon Islands', phone_code: '677' },
		{ code: 'so', name: 'Somalia', phone_code: '252' },
		{ code: 'za', name: 'South Africa', phone_code: '27' },
		{ code: 'gs', name: 'South Georgia and the South Sandwich Islands', phone_code: '500' },
		{ code: 'es', name: 'Spain', phone_code: '34' },
		{ code: 'lk', name: 'Sri Lanka', phone_code: '94' },
		{ code: 'sd', name: 'Sudan', phone_code: '249' },
		{ code: 'sr', name: 'Suriname', phone_code: '597' },
		{ code: 'sj', name: 'Svalbard and Jan Mayen', phone_code: '47' },
		{ code: 'sz', name: 'Swaziland', phone_code: '268' },
		{ code: 'se', name: 'Sweden', phone_code: '46' },
		{ code: 'ch', name: 'Switzerland', phone_code: '41' },
		{ code: 'sy', name: 'Syrian Arab Republic', phone_code: '963' },
		{ code: 'tw', name: 'Taiwan', phone_code: '886' },
		{ code: 'tj', name: 'Tajikistan', phone_code: '992' },
		{ code: 'tz', name: 'Tanzania', phone_code: '255' },
		{ code: 'th', name: 'Thailand', phone_code: '66' },
		{ code: 'tl', name: 'Timor-Leste', phone_code: '670' },
		{ code: 'tg', name: 'Togo', phone_code: '228' },
		{ code: 'tk', name: 'Tokelau', phone_code: '690' },
		{ code: 'to', name: 'Tonga', phone_code: '676' },
		{ code: 'tt', name: 'Trinidad and Tobago', phone_code: '1868' },
		{ code: 'tn', name: 'Tunisia', phone_code: '216' },
		{ code: 'tr', name: 'Turkey', phone_code: '90' },
		{ code: 'tm', name: 'Turkmenistan', phone_code: '993' },
		{ code: 'tc', name: 'Turks and Caicos Islands', phone_code: '1649' },
		{ code: 'tv', name: 'Tuvalu', phone_code: '688' },
		{ code: 'ug', name: 'Uganda', phone_code: '256' },
		{ code: 'ua', name: 'Ukraine', phone_code: '380' },
		{ code: 'ae', name: 'United Arab Emirates', phone_code: '971' },
		{ code: 'gb', name: 'United Kingdom', phone_code: '44' },
		{ code: 'us', name: 'United States', phone_code: '1' },
		{ code: 'uy', name: 'Uruguay', phone_code: '598' },
		{ code: 'uz', name: 'Uzbekistan', phone_code: '998' },
		{ code: 'vu', name: 'Vanuatu', phone_code: '678' },
		{ code: 'va', name: 'Vatican', phone_code: '379' },
		{ code: 've', name: 'Venezuela', phone_code: '58' },
		{ code: 'vn', name: 'Viet Nam', phone_code: '84' },
		{ code: 'vg', name: 'Virgin Islands, British', phone_code: '1284' },
		{ code: 'vi', name: 'Virgin Islands, U.S.', phone_code: '1340' },
		{ code: 'wf', name: 'Wallis and Futuna', phone_code: '681' },
		{ code: 'ye', name: 'Yemen', phone_code: '967' },
		{ code: 'zm', name: 'Zambia', phone_code: '260' },
		{ code: 'zw', name: 'Zimbabwe', phone_code: '263' }
	];

	constructor() {}

	getCountry(code: string) {
		return this.countries.find((c) => {
			return c.code === code;
		});
	}

	getPhoneCode(code) {
		let country = this.countries.find((c) => {
			if (c.code === code) {
				return c.phone_code;
			}
		});

		return country.phone_code;
	}

	getLanguage(code: string) {
		return this.languages.find((l) => {
			return l.code === code;
		});
	}
}
