import { Component, Injector, Type } from '@angular/core';
import { ServiceLocator } from 'src/app/app.module';
import { LoadingService } from 'src/app/services/loading.service';
import { StorageService } from 'src/app/services/storage.service';
import { ToastService } from 'src/app/services/toast.service';
import { TranslateService } from '@ngx-translate/core';
import { EventsService } from '@app/services/events.service';
import { NavigationExtras, Router } from '@angular/router';
import { AlertController, ModalController, NavController } from '@ionic/angular';
import { NavigationService } from '@app/services/navigation.service';

@Component({
	selector: 'app-base',
	templateUrl: './base.page.html',
	styleUrls: ['./base.page.scss']
})
export class BasePage {
	protected loadingService: LoadingService;
	protected toastService: ToastService;
	protected storageService: StorageService;
	public translateService: TranslateService;
	protected eventsService: EventsService;
	protected router: Router;
	protected alertController: AlertController;
	protected navigationService: NavigationService;
	protected modalController: ModalController;

	language: string = 'pt';
	redirect: string = null;

	constructor(public navCtrl?: NavController) {
		const injector: Injector = ServiceLocator.injector;
		this.loadingService = injector.get<LoadingService>(LoadingService as Type<LoadingService>);
		this.toastService = injector.get<ToastService>(ToastService as Type<ToastService>);
		this.storageService = injector.get<StorageService>(StorageService as Type<StorageService>);
		this.translateService = injector.get<TranslateService>(TranslateService as Type<TranslateService>);
		this.eventsService = injector.get<EventsService>(EventsService as Type<EventsService>);
		this.router = injector.get<Router>(Router as Type<Router>);
		this.alertController = injector.get<AlertController>(AlertController as Type<AlertController>);
		this.navigationService = injector.get<NavigationService>(NavigationService as Type<NavigationService>);
		this.modalController = injector.get<ModalController>(ModalController as Type<ModalController>);
	}

	translate(word: string) {
		return this.translateService.instant(word);
	}

	getLanguage() {
		this.language = this.translateService.defaultLang;
	}

	changeLanguage(lang: string) {
		this.language = lang;
		this.translateService.setDefaultLang(lang);
	}

	goTo(path: string, data?: any) {
		let navigationExtras: NavigationExtras = null;

		if (data) {
			navigationExtras = {
				queryParams: data
			};
		}

		this.navCtrl.navigateForward(path, navigationExtras);
	}

	openLink(link: string) {
		window.open(link, '_blank');
	}

	openMaps(lat: string, lng: string) {
		const link = 'https://maps.google.com/?q=' + lat + ',' + lng;
		window.open(link, '_blank');
	}

  checkDate(start, end) {
    const s = new Date(start);
    const e = new Date(end);

    if (s.getDate() === e.getDate()) {
      return true;
    }

    return s.getTime() === e.getTime();
  }
}
