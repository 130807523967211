import { Injectable } from '@angular/core';
import { ApiService } from './api.service';

@Injectable({
	providedIn: 'root'
})
export class EventService {
	private controller = 'event/';

	constructor(private api: ApiService) {}

	all(filter?: any) {
		const { limit, search, page } = filter;
		return this.api.post(this.controller + 'all', { limit, search, page });
	}

	next(filter?: any) {
		const { limit, search, page } = filter;
		return this.api.get(this.controller + 'next', { limit, search, page });
	}
  active(filter?: any) {
		const { limit, search, page } = filter;
		return this.api.get(this.controller + 'active', { limit, search, page });
	}

	one(id: string) {
		return this.api.get(this.controller + 'one', { id });
	}
}
