import { Injectable } from '@angular/core';
import { ApiService } from './api.service';

@Injectable({
	providedIn: 'root'
})
export class EntranceService {
	private controller: string = 'entrance/';

	constructor(private api: ApiService) {}

	all(id: string) {
		return this.api.get(this.controller + 'all', { id });
	}

	one(id: string) {
		return this.api.get(this.controller + 'one', { id });
	}
}
