import { Injectable } from '@angular/core';
import { ApiService } from './api.service';

@Injectable({
	providedIn: 'root'
})
export class TicketService {
	private controller = 'ticket/';

	constructor(private api: ApiService) {}

	active() {
		return this.api.post(this.controller + 'active', null);
	}

	history() {
		return this.api.post(this.controller + 'history', null);
	}

	one(id: string) {
		return this.api.get(this.controller + 'one', { id });
	}

	delete(data: any) {
		return this.api.get(this.controller + 'delete', data);
	}

	archive(data: any) {
		return this.api.get(this.controller + 'archive', data);
	}

	share(data: any) {
		return this.api.post(this.controller + 'share', data);
	}

	transfer(data: any) {
		return this.api.post(this.controller + 'transfer', data);
	}

	transferHistory() {
		return this.api.get(this.controller + 'transfer-history');
	}
}
