import { Injectable } from '@angular/core';
import { ApiService } from './api.service';

@Injectable({
	providedIn: 'root'
})
export class SettingsService {
	private controller: string = 'settings/';

	constructor(private api: ApiService) {}

	partnerCard() {
		return this.api.get(this.controller + 'partner-card');
	}

	all() {
		return this.api.get(this.controller + 'all', { client_type: 'pwa' });
	}
}
