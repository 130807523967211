export const environment = {
	firebase: {
		projectId: 'aaua-pwa',
		appId: '1:295224830268:web:a199ff7629052c152b9639',
		storageBucket: 'aaua-pwa.appspot.com',
		apiKey: 'AIzaSyDmLx8bRoEev3rxhLaqxiGbvWDdRnzDfNE',
		authDomain: 'aaua-pwa.firebaseapp.com',
		messagingSenderId: '295224830268'
	},
	production: false,
	baseUrl: 'https://apipp.360city.pt/wlfun/',
	app: 'AAUAv',
	version: '1.0.2-448',
	identifier: 'com.grupopie.merchant.aauav'
};
